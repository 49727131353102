// Home.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Nosotros from '../components/Nosotros';
import Servicios from '../components/Servicios';
import Hero2 from '../components/Hero2';
import Clientes from '../components/Clientes';
import ContactUs from '../components/ContactUs';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Verificar si el estado tiene el campo scrollToId
    if (location.state && location.state.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <Header />
      <Hero />
      <Nosotros />
      <Servicios />
      <Hero2 />
      <Clientes />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
