import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';



const CarouselCard = ({ title, imageSrc }) => {
  return (
    <div className="bg-white max-w-[404px] max-h-[440px] rounded-3xl flex flex-col items-center justify-center shadow-lg mx-2">
      <img src={imageSrc} alt={title} className="max-w-[305px] max-h-[280px] mb-7 mt-[48px]" />
      <h3 className="text-2xl font-satoshi font-bold text-center mb-[48px]">{title}</h3>
    </div>
  );
};

const ServicesCarousel = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSLide: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="z-40 w-full">
      <CarouselCard title="Limpieza de interiores" imageSrc={`${process.env.PUBLIC_URL}/img/LimInteriores.png`} />
      <CarouselCard title="Limpieza de exteriores" imageSrc={`${process.env.PUBLIC_URL}/img/LimExteriores.png`} />
      <CarouselCard title="Limpieza en altura" imageSrc={`${process.env.PUBLIC_URL}/img/LimAltura.png`} />
      <CarouselCard title="Espacios verdes" imageSrc={`${process.env.PUBLIC_URL}/img/LimVerdes.png`} />
      {/* Agrega más tarjetas aquí si es necesario */}
    </Slider>
  );
};

const Servicios = () => {

const whatsappNumber = '5493513064064';
  return (
    <div className="relative h-[667px] w-full">
      <div className="bg-[#228B60] h-2/3 flex flex-col justify-start relative px-4">
        <h2 id='servicios' className="text-white text-4xl font-bold pt-[70px] pb-[40px] mb-4 font-maison text-left lg:ml-20">Servicios</h2>
        <div onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')} className="lg:absolute lg:right-24 lg:top-14 bg-[#E0E0E0] w-[117px] h-[52px] items-center justify-center rounded-lg shadow-md lg:mb-0 mb-4 px-6 py-4 font-satoshi font-bold hidden lg:flex">
          Contacto
        </div>
        <div className="flex items-center justify-center flex-grow">
          <ServicesCarousel />
        </div>
      </div>
      <div className="bg-[#3D3D40] h-1/3"></div>
    </div>
  );
};

export default Servicios;
